import React, { useEffect, useState } from 'react';
import {Container, Row, Col, Card, Form, Button, Modal} from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { Api } from '../../../api/api';
import stateAction from '../../../store/state/actions';
import { useHistory } from 'react-router-dom';

const CreateRelation = (props) => {
    const [parents, setParents] = useState([]);
    const [spouse, setSpouse] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const updateParent = (evt, ix) => {
        let pval = evt.target.value;
        setParents(c => {
            c[ix].displayName = pval;
            return [...c];
        })
    }
    const addSpouse = () => {
        let subType = (props?.user?.gender === "male") ? "wife" : "husband";
        setSpouse(c => {
            let tc = [...c];
            tc = [...tc, {displayName: "", subType: subType, gender: (subType === "wife") ? "female" : "male", children: []}];
            return tc;
        })
    }
    const addChild = (ix, subType) => {
        setSpouse(c => {
            let tc = c.map((sc, si) => {
                if(si === ix) {
                    let children = [...sc.children, {displayName: "", subType: subType, gender: (subType === "son") ? "male" : "female" }];
                    return {...sc, children: children};
                }
                return sc;
            });
            return tc;
        })
    }
    const updateSpouse = (evt, ix) => {
        let pval = evt.target.value;
        setSpouse(c => {
            c[ix].displayName = pval;
            return [...c];
        })
    }
    const updateChild = (evt, ix, ic) => {
        let pval = evt.target.value;
        setSpouse(c => {
            c[ix].children[ic].displayName = pval
            return [...c];
        })
    }
    const onSaveFamily = () => {
        let tParents = parents.filter(p => p.displayName && p.displayName.trim());
        let tSpouse = spouse.filter(p => p.displayName && p.displayName.trim());
        let relObj = {userId: props?.user?.id, familyId: props?.user?.familyId, parents: [...tParents], spouse: [...tSpouse]};
        dispatch(stateAction.setIsLoading(true));
        Api.addRelation(relObj).then(resp => {   
            console.log("Added relations")   
            dispatch(stateAction.setIsLoading(false));
            if(props.onSave) {
                props.onSave();
            } else {
                history.push(`/family/tree/${props.user.id}`);
            }
        }).catch(err => {
            dispatch(stateAction.setIsLoading(false));
        });
    }
    useEffect(() => {
        if(props?.user?.id) {
            Api.getRelations(props.user.id).then(resp => {
                let userId = props.user.id;
                let userDict = resp?.data?.userDict ?? {};
                let rels = resp?.data?.relations ?? [];
                let tParents =  [];
                let spouseDict = {};
                rels.map(rel => {
                    if(rel.type === "parent") {
                        if(rel.userId != userId && rel.relationUserId !== userId) {
                            if(!spouseDict[rel.relationUserId]) {
                                spouseDict[rel.relationUserId] = {...userDict[rel.relationUserId], userId: userDict[rel.relationUserId].id, children: []}
                            }
                            spouseDict[rel.relationUserId].children.push({...userDict[rel.userId], userId: userDict[rel.userId].id});
                        }
                        if(rel.userId === userId) {
                            tParents.push({...userDict[rel.relationUserId], userId: userDict[rel.relationUserId].id});
                        }
                    } else if(rel.type === "spouse") {
                        if(rel.userId === userId) {
                            if(!spouseDict[rel.relationUserId]) {
                                spouseDict[rel.relationUserId] = {...userDict[rel.relationUserId], userId: userDict[rel.relationUserId].id, children: []}
                            }
                        } else if(rel.relationUserId === userId) {
                            if(!spouseDict[rel.userId]) {
                                spouseDict[rel.userId] = {...userDict[rel.userId], userId: userDict[rel.userId].id, children: []}
                            }
                        }
                    }
                })
                let plen = tParents.length;

                if(plen === 0) {
                    tParents.push({gender: "male", subType: "father"});
                    tParents.push({gender: "female", subType: "mother"});
                } else if(plen === 1) {
                    let pgen = parents[0].gender === "male" ? "female" : "male";
                    let pstype = pgen === "male" ? "father" : "mother";
                    tParents.push({gender: pgen, subType: pstype});
                }
                setSpouse(Object.values(spouseDict));
                setParents(tParents);
            });
        }
    }, props?.user?.id)
    return (
        <Container>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Body className="text-center">
                            <h2>{props.user?.displayName}</h2>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Body className="text-center">
                            <div className="text-center">
                                <h3>Parents</h3>
                            </div>
                            <div className="text-start">
                                {parents.map((p, ix) => {
                                    let isDisabled = p.id ? true : false;
                                    return (
                                        <Card>
                                        <Card.Body>
                                            <Row>
                                                <Col sm="12">
                                                    <h4>{p.gender ===  "male" ? "Father" : "Mother"}</h4>
                                                </Col> 
                                                <Col sm="12">
                                                    <Form.Control type="text" value={p.displayName} disabled={isDisabled} onChange={(evt) => updateParent(evt, ix)}/>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>    
                                )
                                })}
                            </div>
                        </Card.Body>
                    </Card>

                </Col>
                {spouse.map((s, ix) => {
                    let isDisabled = s.id ? true : false;
                    return (
                        <Col sm="12">
                            <Card>
                                <Card.Body className="text-right">
                                        <div className="float-end">
                                            <i className="material-symbols-outlined me-1 text-danger">delete</i>
                                        </div>
                                        <div className="text-center">
                                            <h3>
                                                Spouse and children
                                            </h3>                                            
                                        </div>
                                        <div className="text-start">
                                            <Card>
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm="2">
                                                            <h4>{(props.user?.gender === "male") ? "Wife" : "Husband"}</h4>
                                                        </Col> 
                                                        <Col sm="6">
                                                            <Form.Control type="text" value={s.displayName} disabled={isDisabled} onChange={(evt) => updateSpouse(evt, ix)}/>
                                                        </Col>
                                                        <Col sm="4">
                                                            <button className="btn btn-primary" onClick={() => addChild(ix, "son")}>                            
                                                                + Son
                                                            </button>
                                                            &nbsp;
                                                            <button className="btn btn-primary" onClick={() => addChild(ix, "daughter")}>+ Daughter</button>
                                                        </Col>                    
                                                    </Row>

                                                {s.children.map((c, ic) => {
                                                    let isChDisabled = c.id ? true : false;
                                                    return (
                                                    <Row className="ms-2 mt-2">
                                                        <Col sm="2">
                                                            <h4>{c.gender === "male" ? "Son" : "Daughter"}</h4>
                                                        </Col> 
                                                        <Col sm="6">                                                                
                                                            <Form.Control type="text" id="name" value={c.displayName} disabled={isChDisabled} onChange={(evt) => updateChild(evt, ix, ic)}/>
                                                        </Col>
                                                    </Row>
                                                    )
                                                })}
                                                </Card.Body>
                                            </Card>
                                        </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    )
                })}
                <Card>
                    <div className="text-center">
                        <h4>Married ? click to add spouse below</h4>
                    </div>
                    <Card.Body className="text-center">
                        <button type="button" className="btn btn-primary col-10" onClick={() => addSpouse()}>Add Spouse</button>
                    </Card.Body>
                </Card>
            </Row>
            <Row className="mt-3 text-center">
                <Col sm="12">
                    <Card>
                        <Card.Body className="text-right"> 
                            {(props.onCancel ? (
                                <button className="btn btn-info col-4" onCancel={props.onCancel}>
                                    Cancel
                                </button>
                            ) : (""))}
                            <button className="btn btn-success col-4" onClick={onSaveFamily}>
                                Save Family Information
                            </button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default CreateRelation;