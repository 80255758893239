import React, { useEffect, useState } from 'react';
import {Container, Row, Col, Card, Form, Button, Modal} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { Api } from '../../../api/api';
import userAction from '../../../store/user/actions';
import stateAction from '../../../store/state/actions';
import { Typeahead } from 'react-bootstrap-typeahead';
import { get_families } from '../../../store/family/selectors';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { CreateFamilyModal } from '../family/createFamilyModal';

const EditUserModal = (props) => {
    const [user, setUser] = useState({});
    const changeHandler = (evt) => {
      if(evt && evt.target.name) {
          let pname = evt.target.name;
          let pvalue = evt.target.value;
          setUser(c => {
            c[pname] = pvalue;
            return {...c};
          });
          if(props.changeHandler) {
            props.changeHandler(pname, pvalue);
          }
      }
    }
    const handleSave = () => {
        let toSave = {displayName: user.displayName, nickName: user.nickName, gender: user.gender};
        Api.updateUser(user.id, toSave).then(resp => { 
            if(props.onSave) {
                props.onSave(resp);
            }
        }).catch(err => {  
      });
    }

    const onHide = () => {
        if(props.onHide) {
            props.onHide();
        }
    }
    useEffect(() => {
        if(props?.userId) {
            Api.getUser(props?.userId).then(resp => {
                setUser({...resp.data});
            })
        }
    }, [props.userId])
    return (
        <Modal size="lg" centered show={props.show} onHide={onHide}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <Card.Body>
                                    <Form>
                                        <Form.Group className="form-group">
                                            <Form.Label>Name:</Form.Label>
                                            <Form.Control type="text" id="name" name="displayName" value={user.displayName} onChange={changeHandler}/>
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Label>Nick Name:</Form.Label>
                                            <Form.Control type="text" id="name" name="nickName" value={user.nickName} onChange={changeHandler}/>
                                        </Form.Group>
                                        <Form.Group className="form-group col-sm-6">
                                            <Form.Label className="form-label d-block">Gender:</Form.Label>
                                            <Form.Check className="form-check form-check-inline">
                                                <Form.Check.Input className="form-check-input" type="radio" name="gender" id="inlineRadio10" defaultValue="male" checked={user?.gender === "male"} onChange={changeHandler}/>
                                                <Form.Check.Label className="form-check-label" htmlFor="inlineRadio10">Male</Form.Check.Label>
                                            </Form.Check>
                                            <Form.Check className="form-check form-check-inline">
                                                <Form.Check.Input className="form-check-input" type="radio" name="gender" id="inlineRadio11" defaultValue="female" checked={user?.gender === "female"} onChange={changeHandler}/>
                                                <Form.Check.Label className="form-check-label" htmlFor="inlineRadio11">Female</Form.Check.Label>
                                            </Form.Check>
                                        </Form.Group>
                                        <div className="d-flex justify-content-end">
                                            <Button variant="primary" onClick={handleSave}>{props.saveText ? props.saveText : "Save"}</Button>
                                            <Button variant="danger" onClick={onHide}>Cancel</Button>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    )
}

export default EditUserModal;