import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import userAction from '../store/user/actions';
import firebase from 'firebase/compat/app';
import SignIn from '../views/dashboard/auth/sign-in';
import UserProfileEdit from '../views/dashboard/app/user-profile-edit';
import { Api } from '../api/api';
import { get_user} from '../store/user/selectors';
import familyAction from '../store/family/actions';
import stateAction from '../store/state/actions';
import CreateUser from '../views/dashboard/user/createUser';
import Dashboard from '../layouts/dashboard';
import { is_load_families } from '../store/state/selectors';
import { useHistory } from 'react-router-dom';
import NoAccess from '../views/dashboard/user/noAccess';

const IndexRouters = () => {

    const user = useSelector(get_user);
    const isLoadFamilies = useSelector(is_load_families);
    const dispatch = useDispatch();
    let tryGap = 200;
    const lookForUserInfo = (user) => {
        Api.getSelf().then(resp => {
            dispatch(userAction.setUserProperty({...resp.data, userExist: true})) //, uid: ""
            dispatch(stateAction.setIsLoading(false));
        }).catch(resp => {
            //irrespective of response, setLoading as false
            dispatch(stateAction.setIsLoading(false));
        })
    }
    const loadUserInfo = (user) => {
        if(tryGap > 10000) {
            dispatch(stateAction.setIsLoading(false));
            return;
        }
        if(user && user.uid) {
            dispatch(stateAction.setIsLoading(true));
            Api.getUserByUid(user.uid).then(resp => {
                dispatch(userAction.setUserProperty({...resp.data, userExist: true}))
                dispatch(stateAction.setIsLoading(false));
            }).catch(resp => {
                if(resp && resp.status === 404) {
                    lookForUserInfo();
                } else if(resp && resp.status === 403) {
                    //this is an issue, user dont exist in our system
                    dispatch(stateAction.setIsLoading(false));
                } else {
                    console.log("here is the response");
                    tryGap = tryGap * 2;
                    setTimeout(() => loadUserInfo(user), tryGap);
                    console.log("Error getting document");
                }
            })
        }
    }
    const loadFamilies = () => {
        return;
        Api.getFamilies().then(resp => {
            let famArr = resp?.data;
            if(!famArr) {
                famArr = [];
            }
            famArr.map(fam => {
                dispatch(familyAction.set({familyId: fam.id, familyObj: {...fam}}))
            })
        }).catch(resp => {
            if(resp.status === 404 || resp.status === 403) {
                //do nothing
            } else {            
                tryGap = tryGap * 2;
                setTimeout(() => loadFamilies(), tryGap);
                console.log("Error getting document");
            }
        })
    }
    const onAddUser = (resp) => {
        if(resp && resp.data) {
            dispatch(userAction.setUserProperty({...resp.data, userExist: true}));
        }
    }
    useEffect(() => {
        if(user && user.isAuthenticated && isLoadFamilies) {
            loadFamilies();
            dispatch(stateAction.setIsLoadFamilies(false));
        }
    }, [user, isLoadFamilies])
    // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
      dispatch(userAction.setUserProperty({isLoading: true}));
      const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async user => {
        if(!!user) {
            dispatch(userAction.login());
            dispatch(userAction.setUser({displayName: user.displayName, 
                email: user.email, isEmailVerified: user.emailVerified, 
                phoneNumber: user.phoneNumber, isPhoneVerified: user.phoneNumber ? true: false,
                photoURL: user.photoURL, uid: user.uid}));
            loadUserInfo(user);
            loadFamilies();
        } else {
            dispatch(userAction.logout());
            dispatch(stateAction.setIsLoading(false));
        }
      });
      return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);
    if(!user.isAuthenticated) {
        return (
            <SignIn />
        )
    } else if(!user.userExist) {
        return ( 
            <NoAccess />
        )
    } else if(!user.uid) {
        return (
            <CreateUser user={{...user}} onSave={onAddUser}/>
        )
    } else {
        return (
            <Dashboard />
        )
    }
}

export default IndexRouters
