import React, { useState } from 'react';
import {Container, Row, Col, Card, Form, Button, Modal} from 'react-bootstrap'
import { Api } from '../../../api/api';
import stateAction from '../../../store/state/actions';
import { useDispatch } from 'react-redux';

const CreateFamily = (props) => {
    const [family, setFamily] = useState({});
    const dispatch = useDispatch();

    const changeHandler = (evt) => {
        if(evt && evt.target.name) {
            let dict = {};
            let pName = evt.target.name;
            let pVal = evt.target.value;
            setFamily(c => {
                c[pName] = pVal;
                return c;
            });
        }
    }
    const createFamily = () => {
        dispatch(stateAction.setIsLoading(true));
        Api.createFamily({...family}).then(resp => {
          if(props.onSave) {
            props.onSave();
          }  
        }).catch(err => {
            console.log("Error saving");
        }).then(() => {
            dispatch(stateAction.setIsLoading(false));
        });
    }
    return (
        <>
        <Container>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Body>
                            <Form>
                                <Form.Group className="form-group">
                                    <Form.Label>Name:</Form.Label>
                                    <Form.Control type="text" id="familyname" name="name" onChange={changeHandler}/>
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label>Known last names</Form.Label>
                                    <Form.Control as="textarea" rows="5"  name="lastNames" onChange={changeHandler}></Form.Control>
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label>Current country:</Form.Label>
                                    <Form.Control type="text" id="familyCountry" name="country" onChange={changeHandler}/>
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label>Current state:</Form.Label>
                                    <Form.Control type="text" id="familyState" name="state" onChange={changeHandler}/>
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label>Current city:</Form.Label>
                                    <Form.Control type="text" id="familyCity" name="city" onChange={changeHandler}/>
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label>More details about family</Form.Label>
                                    <Form.Control as="textarea" rows="5" name="bio" onChange={changeHandler}></Form.Control>
                                </Form.Group>
                                <Button variant="primary" onClick={createFamily}>Create</Button>{' '}
                                <Button variant="danger">Cancel</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default CreateFamily;