import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash'

export const initialState = {
  isLoading: true,
  isLoadFamilies: true
};

export const userSlice = createSlice({
  name: 'state',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload ? action.payload : false;
    },
    setIsLoadFamilies: (state, action) => {
      state.isLoadFamilies = action.payload ? action.payload : false;
    }
  },
});

export default userSlice.reducer;
