import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './state.ts';
import _ from 'lodash'

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      state.user.isAuthenticated = true;
    },
    logout: (state, action) => {
      state.user.isAuthenticated = false;
    },
    setUser: (state, action) => {
      let user = action.payload ? action.payload : {};
      state.user = {...state.user, ...user};
    },
    setUserProperty: (state, action) => {
      let user = action.payload ? action.payload : {};
      for(var key in user) {
        if(user[key] === undefined) {
          delete user[key];
        }
      }
      state.user = {...state.user, ...user};
    }
  },
});

export default userSlice.reducer;
