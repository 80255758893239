import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// Configure Firebase.
const config = {
    apiKey: "AIzaSyAfNilAOdpGnifTtry8E1tOjmcOjIOXRrc",
    authDomain: "kulstry.firebaseapp.com",
    projectId: "kulstry",
    storageBucket: "kulstry.appspot.com",
    messagingSenderId: "212843553149",
    appId: "1:212843553149:web:dbc2f97fa56cc3c131ac14",
    measurementId: "G-L53R232CK5"
};
firebase.initializeApp(config);

// Configure FirebaseUI.
//        firebase.auth.GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD,

const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      {        
        provider: firebase.auth.PhoneAuthProvider.PHONE_SIGN_IN_METHOD,
        defaultCountry: "IN"
      }
    ],
};

function SignInScreen() {
  return (
    <div>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </div>
  );
}

export default SignInScreen