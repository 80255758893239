import React, { useEffect, useState } from 'react';
import {Container, Row, Col, Card, Form, Button, Modal} from 'react-bootstrap'
import { Api } from '../../../api/api';
import stateAction from '../../../store/state/actions';
import { useDispatch } from 'react-redux';
import CreateUser from '../user/createUser';

const FamilyMembers = (props) => {
    const [users, setUsers] = useState([]);
    const [name, setName] = useState("");
    const [familyId, setFamilyId] = useState("");
    const [editUser, setEditUser] = useState(null);
    useEffect(() => {
        Api.getUsers(name, familyId).then(resp => {
            setUsers([...resp.data])
        })
    }, [name, familyId]);
    const onAddUser = (resp)=> {
        if(resp && resp.data) {
            setUsers(c => {
                c.push({...resp.data});
                return [...c];
            })
            setEditUser(null);
        }
    }
    const changeHandler = (evt) => {
        let pvalue = evt.target.value;
        setName(pvalue);
    }
    return (
        <>
        <Container>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Body>
                            <Form>
                                <Form.Group className="form-group">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" id="name" placeholder="type name to filter"  onChange={changeHandler}/>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm="12">
                    <Card>
                        <Card.Body>
                            <div className="d-flex justify-content-between">
                                <h5 className="card-title">Existing Members</h5>
                            </div>
                            <div>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map(u => {
                                            if(name?.trim() && u.displayName?.toLowerCase().indexOf(name.toLowerCase()) === -1) {
                                                return ("");
                                            }
                                            if(familyId?.trim() && u.familyId?.toLowerCase().indexOf(familyId.toLowerCase()) === -1) {
                                                return ("");
                                            }
                                            return (
                                                <tr>
                                                    <td>{u.displayName}</td>
                                                    <td>{u.nickName}</td>
                                                    <td>{u.gender}</td>
                                                    <td><button className="btn btn-link" onClick={() => setEditUser({...u})}>Edit User</button></td>
                                                    <td><a href={`/family/relation/${u.id}`}>Edit Family</a></td>
                                                    <td><a href={`/family/tree/${u.id}`}>Family Tree</a></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default FamilyMembers;