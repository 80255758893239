import { createSelector } from "@reduxjs/toolkit";

// Selectors
export const getFamilyById = createSelector(
    [
        state => state.family.families,
        (state, familyId) => familyId
    ],
    (families, familyId) => (familyId && families[familyId]) ? families[familyId] : {}
);

export const get_families = (state) => state.family.families;
