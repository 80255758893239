import firebase from 'firebase/compat/app';
const auth = firebase.auth();
export const get = async (url) => {
    const user = auth.currentUser;
    const token = user && (await user.getIdToken());
    let promise = new Promise((resolve, reject) => {
        let headers = {
        'Accept': 'application/json',
        'Content-Type': 'x-www-form-urlencoded',
        'Authorization': `Bearer ${token}`
      }
      fetch(url, {
        method: 'GET',
        headers: headers
      }).then(response => {
        if(response.ok) {
          response.text().then(data => {
            resolve({data: data ? JSON.parse(data) : {}});
          })
          .catch((error) => {
            reject(error);
          });
        } else {
          reject(response);
        }
      });
    });
    return promise;
  }
export const post = async(url, data, isJson) => {
    const user = auth.currentUser;
    const token = user && (await user.getIdToken());

    let promise = new Promise((resolve, reject) => {
        let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Authorization': `Bearer ${token}`
      }
      let fdata = null;
      if(isJson) {
        headers['Content-Type'] = 'application/json; charset=utf-8'
        fdata = JSON.stringify(data);
      } else {
        fdata = new URLSearchParams();
        for (const pair of data) {
          fdata.append(pair[0], pair[1]);
        }      
      }
      fetch(url, {
        method: 'POST',
        headers: headers,
        body: fdata
      }).then(response => {
        if(response.ok) {
          console.log("Here is the response", response.headers.get("Content-Type"));
          let contentType = response.headers.get("Content-Type");
          if(contentType.indexOf("image") > -1) {
            response.blob().then(blob => {
              resolve(URL.createObjectURL(blob));
            })
          } else {
            response.text().then(data => {
              resolve({data: data ? JSON.parse(data) : {}});
            })
            .catch((error) => {
              reject(error);
            });
          }
        } else {
            reject(response);
        }
      })
    });
    return promise
  }
  
  export const put = async(url, data, isJson) => {
    const user = auth.currentUser;
    const token = user && (await user.getIdToken());

    let promise = new Promise((resolve, reject) => {
        let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Authorization': `Bearer ${token}`
      }
      let fdata = null;
      if(isJson) {
        headers['Content-Type'] = 'application/json; charset=utf-8'
        fdata = JSON.stringify(data);
      } else {
        fdata = new URLSearchParams();
        for (const pair of data) {
          fdata.append(pair[0], pair[1]);
        }      
      }
      fetch(url, {
        method: 'PUT',
        headers: headers,
        body: fdata
      }).then(response => {
        if(response.ok) {
          response.text().then(data => {
            resolve({data: data ? JSON.parse(data) : {}});
          })
          .catch((error) => {
            reject(error);
          });
        } else {
            reject(response);
        }
      })
    });
    return promise
  }