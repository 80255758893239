import React, { useState } from 'react';
import CreateUserRelation from './createUserRelation';
const { Modal } = require("react-bootstrap")

export const CreateRelationModal = (props) => {
    return (
        <Modal size="lg" centered show={props.show} onHide={props.onHide}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <CreateUserRelation userId={props.userId} onHide={props.onHide} onSave={props.onSave}/>
            </Modal.Body>
        </Modal>
    )
}
