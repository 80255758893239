import React, { useEffect, useState } from 'react';
import {Container, Row, Col, Card, Form, Button, Modal} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { Api } from '../../../api/api';
import { getFamilyById, get_families } from '../../../store/family/selectors';
import { get_user } from '../../../store/user/selectors';
import familyAction from '../../../store/family/actions';
import { Tree } from 'react-tree-graph';
import CreateRelation from './createRelation';
import DrawFamily from './DrawFamily';

const CreateFamily = (props) => {
    const [dUser, setDUser] = useState({}); 
    const [selectedFamilyId, setSelectedFamilyId] = useState("");
    const user = useSelector(get_user);
    const families = useSelector(get_families);
    const family = useSelector(state => getFamilyById(state, selectedFamilyId));
    const dispatch = useDispatch();
    useEffect(() => {
        if(props?.match?.params?.userId) {
            loadUser(props?.match?.params?.userId);
        } else {
            setDUser(user);
            if(!selectedFamilyId) {
                setSelectedFamilyId(user.primaryFamilyId);
            }
        }
        
    }, [user]);
    useEffect(() => {
        if(selectedFamilyId) {
            Api.getFamilyDetails(selectedFamilyId).then(resp => {
                const familyObj = resp.data.response;
                dispatch(familyAction.set({familyId: selectedFamilyId, familyObj: familyObj}))
            }).catch(err => {
                console.log("api err response", err);
            })
        }
    }, [selectedFamilyId]);

    const loadUser = (userId) => {
        Api.getUser(userId).then(resp => {
            let dUser = {...resp.data.response};
            setDUser(dUser);
            if(!selectedFamilyId) {
                setSelectedFamilyId(dUser.primaryFamilyId);
            }
        }).catch(resp => {
            console.log("Error getting document", resp);
        })
    }
    const familyChangeHandler = (evt) => {
        let value = evt?.target?.value;
        console.log("Here is the familyId", value);
        setSelectedFamilyId(value);
    }
    console.log("Here is the family and dUser", family, dUser);
    return (
        <>
        <div>
        <Container>
            <Row>
                <Col sm="12">
                    <Card className="position-relative inner-page-bg bg-primary" style={{height: "150px"}}>
                        <div className="inner-page-title">
                            <h3 className="text-white">Add Family Members</h3>
                            <p style={{margin: "10px"}}>
                                <Form>
                                    <Form.Group className="form-group col-sm-12">
                                        <Form.Select defaultValue="" name="selectedFamilyId" className="form-select" aria-label="Default select example" onChange={familyChangeHandler}>
                                            <option value="">Select</option>
                                            {Object.values(families).map(fam => {
                                                if(dUser && (dUser.primaryFamilyId === fam.id || (dUser.belongTo && dUser.belongTo.indexOf(fam.id) > -1))) {
                                                    return <option value={fam.id} selected={selectedFamilyId === fam.id}>{fam.name}</option>
                                                }
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Form>
                            </p>
                        </div>
                    </Card>
                </Col>
                <Col sm="12">
                    <Card>
                        <Card.Body>
                            {selectedFamilyId ? (
                                <DrawFamily family={family} user={dUser}/>
                            ) : (
                                <div>
                                    Please select Family
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
        </div>
        </>
    )
}

export default CreateFamily;