import React, { useEffect, useState } from 'react';
import {Container, Row, Col, Card, Form, Button, Modal} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { Api } from '../../../api/api';
import { get_families } from '../../../store/family/selectors';
import userAction from '../../../store/user/actions';
import { get_user } from '../../../store/user/selectors';
import CreateFamily from './createFamily';
import CreateRelation from './createRelation';

const CreateUserRelation = (props) => {
    const [user, setUser] = useState({});
    useEffect(() => {
        let userId = "";
        if(props?.userId) {
            userId = props.userId;
        } else {
            userId = props?.match?.params?.userId;
        }
        if(userId) {
            Api.getUser(userId).then(resp => {
                setUser({...resp.data});
            })
        }
    }, [props])
    return (
        <CreateRelation user={{...user}} onClose={props.onHide} onSave={props.onSave}/>
    )
}

export default CreateUserRelation;