import { Link } from 'react-router-dom'
import { Card } from "react-bootstrap"

import img1 from '../../../assets/images/small/07.png'
import img2 from '../../../assets/images/small/08.png'
import img3 from '../../../assets/images/small/09.png'

export const QuickLinks = () => {
    return (
        <Card className=" card-block card-stretch card-height">
            <div className="card-header d-flex justify-content-between">
                <div className="header-title">
                    <h4 className="card-title">Quick Links</h4>
                </div>
            </div>
            <Card.Body>
                <div>
                    <ul className=" post-opt-block d-flex list-inline m-0 p-0 flex-wrap">
                        <li className="me-3 mb-md-0 mb-2">
                            <Link to="/family/relation" className="btn btn-soft-primary">
                                <img src={img1} alt="icon" className="img-fluid me-2"/> Add Family Member
                            </Link>
                        </li>
                        <li className="me-3 mb-md-0 mb-2">
                            <Link to="/family/members" className="btn btn-soft-primary">
                                <img src={img2} alt="icon" className="img-fluid me-2"/> View Family Member
                            </Link>
                        </li>
                        <li className="me-3">
                            <Link to="/family/tree" className="btn btn-soft-primary">
                                <img src={img3} alt="icon" className="img-fluid me-2"/> View Family Tree
                            </Link>
                        </li>
                    </ul>
                </div>
            </Card.Body>
        </Card>
    )
}