import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { get_user } from '../../../store/user/selectors';
import { Api } from '../../../api/api';

import './familyTree.css';  // contains .diagram-component CSS
import { CreateRelationModal } from './createRelationModal';
import EditUserModal from '../user/editUserModal';
import { saveAs } from 'file-saver';

const FamilyTree = (props) => {
    const loggedUser = useSelector(get_user);
    const [user, setUser] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [svgWidth, setSvgWidth] = useState(500);
    const [svgHeight, setSvgHeight] = useState(500);
    const [svgComponents, setSvgComponents] = useState([]);
    const [showRelationModal, setShowRelationModal] = useState(false);
    const [showUserModal, setShowUserModal] = useState(null);
    const svgRef = React.useRef(null);
    const entityWidth = 200;
    const entityheight = 90;
    const entityGap = 50;
    const xmargin = 50;
    const ymargin = 50;
    const vGap = 250;
    const [membersDict, setMembersDict] = useState({});
    const [root, setRoot] = useState(null);

    const exportImage = () => {
        let svg_data = document.getElementById("familytree-svg").innerHTML //put id of your svg element here

        let head = `<svg title="familyTree" version="1.1" xmlns="http://www.w3.org/2000/svg" width="${svgWidth}" height="${svgHeight}">`

        //if you have some additional styling like graph edges put them inside <style> tag

        let style = `<style>
        .svg-wrapper {
            overflow: scroll;
        }
        .node-info-wrapper {
            position: relative;
        }
        .node-info-wrapper {
            width: 100%;
            height: 90px;
        }
        .node-info {
            height: 100%;
        }
        .node-name {
            color: #555;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            font-size: 1em;
        }
        .node-mouse-over {
            position: absolute;
            padding: 5px;
            top: 0px;
            left: 0px;
            height: 100%;
            flex-flow: row;
            width: 100%;    
            display: none;
            background-color: #DDD; 
            justify-content: space-evenly;
            align-items: center;
        }
        </style>`

        let full_svg = head +  style + svg_data + "</svg>"
        //let blob = new Blob([full_svg], {type: "image/svg+xml"});
        let fdata = new FormData();
        fdata.append("svg", full_svg);
        Api.convertToPng(fdata).then(resp => {
            //console.log("resp", resp);
            let domUrl = window.URL || window.webkitURL || window;
            //let blob = domUrl.createObjectURL(resp);
            //let blob = new Blob([resp], {type: "image/png"});
            saveAs(resp, "familyTree-" + user.displayName + ".png")
        });
        //var domUrl = window.URL || window.webkitURL || window;
        //saveAs(blob, "familyTree" - + user.displayName + ".svg");
        /*
        let svgElem = document.getElementById("familytree-svg");
        var serializer = new XMLSerializer();
        var source = serializer.serializeToString(svgElem);
        //source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
        source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
        console.log("Here is the source", source);
        source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
        var svgDataUrl = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source);
        */
       /*
        var svgDataUrl = domUrl.createObjectURL(blob);

    //    console.log(svgDataUrl);
        var image = new Image();
        image.onload = () => {
            console.log("This is onload call")
            var canvas = document.createElement('canvas');
            var scale = 1;
            canvas.width = svgWidth * scale;
            canvas.height = svgHeight * scale;
            canvas.getContext('2d')
            .drawImage(image, 0, 0, svgWidth * scale, svgHeight * scale);
            var pngDataUrl = canvas.toDataURL();
            //console.log("pngDataUrl", pngDataUrl);
            saveAs(pngDataUrl, "familyTree.png")
        };
        image.setAttribute('crossorigin', 'anonymous');
        image.src = svgDataUrl;
        */
    }
    const setNodeValue = (userDict, node) => {
        if(node.userId && userDict[node.userId]) {
            let obj = userDict[node.userId];
            node.displayName = obj.displayName ?? "";
            node.gender = obj.gender ?? "";
            node.nickName = obj.nickName ?? "";
        }
    }
    const findDimension = (userDict, node, maxHeight=0) => {
        if(!node) {
            return maxHeight;
        }
        if(entityheight > maxHeight) {
            maxHeight = entityheight;
        }
        let nodeWidth = entityWidth;
        let childrenWidth = 0;
        if(node.spouse) { 
            let spouseCnt = node.spouse.length;
            nodeWidth = entityWidth + (spouseCnt * (entityWidth + entityGap));
            let addGap = false;
            for(var si in node.spouse) {
                let snode = node.spouse[si];
                setNodeValue(userDict, snode)
    
                for(let ci in snode.children) {
                    let chNode = snode.children[ci];
                    let chHeight = findDimension(userDict, chNode, (maxHeight + vGap + entityheight));
                    if(chHeight > maxHeight) {
                        maxHeight = chHeight;
                    }
                    childrenWidth += chNode.width;
                    if(addGap) {
                        childrenWidth += entityGap;
                    }
                    addGap = true;
                }
            }
        }
        let totalWidth = childrenWidth > nodeWidth ? childrenWidth : nodeWidth;
        node.width = totalWidth;
        node.height = entityheight;
        setNodeValue(userDict, node)
        return maxHeight;
    }    

    const setLayout = (userDict, node, offsetx, offsety, svgArr) => {
        let spouseCnt = node.spouse.length;
        let nodeWidth = entityWidth + (spouseCnt * (entityWidth + entityGap));

        let x = xmargin + offsetx + (node.width - nodeWidth)/2;
        svgArr.push({type:"rect", name: node.displayName, id: node.userId, gender: node.gender, width: entityWidth, height: entityheight, x: x, y: offsety});
        node.x = x;
        let chy = offsety + entityheight + vGap;
        let chOffsetx = offsetx;
        for(var si in node.spouse) {
            let snode = node.spouse[si];
            let spx = x + entityWidth + entityGap;
            let linex = x+entityWidth;
            let liney = offsety + entityheight/2;
            svgArr.push({type:"line", x1: linex, x2: spx, y1: liney, y2: liney});
            let lineMidx = (linex+spx)/2;
            let lineMidy = offsety + entityheight + vGap/2;
            svgArr.push({type:"rect",name: snode.displayName, id: snode.userId, gender: snode.gender, width: entityWidth, height: entityheight, x: spx, y: offsety});

            if(snode.children.length > 0) {
                svgArr.push({type:"line", x1: lineMidx, x2: lineMidx, y1: liney, y2: lineMidy});
            }
            for(let ic in snode.children) {
                let chNode = snode.children[ic];
                setLayout(userDict, chNode, chOffsetx, chy, svgArr)
                chOffsetx += chNode.width + entityGap;

                let chMidx = chNode.x + entityWidth/2;
                let chMidy = chy - vGap/2;
                svgArr.push({type:"line",x1: chMidx, x2: chMidx, y1: chy, y2: chMidy});
                svgArr.push({type:"line",x1: chMidx, x2: lineMidx, y1: chMidy, y2: chMidy});
            }
        }
    }

    const loadTree =() => {
        if(user?.id) {
            Api.getFamilyTreeForUser(user.id).then(resp => {
                if(resp?.data) {
                    let userDict = resp.data.userDict;
                    let familyTree = resp.data.familyTree;
                    if(!familyTree || !familyTree.userId) {
                        return;
                    }
                    let maxHeight = findDimension(userDict, familyTree, entityheight + 2*ymargin);
                    if(!isNaN(familyTree.width) && familyTree.width > 500) {
                        setSvgWidth(familyTree.width+200);
                    }
                    if(!isNaN(maxHeight) && maxHeight > 500) {
                        setSvgHeight(maxHeight);
                    }
                    let svgArray = [];
                    setLayout(userDict, familyTree, 0, ymargin, svgArray);
                    setSvgComponents(svgArray);
                }
            }).catch(err => {
                console.log("here is the error", err);
            })
        }
    }
    useEffect(() => {
        let userId = props?.match?.params?.userId;
        if(userId) {
            setUser({id: userId});
        } else {
            setUser(loggedUser);
        }
    }, [])
    useEffect(() => {
        loadTree();
    }, [user]);
    const inviteUser = (obj) => {
        Api.inviteUser({id: obj.id, name: obj.name});
    }
    const relationModalHandler = (userId) => {
        setSelectedUserId(userId);
        setShowRelationModal(true);
    }
    const userModalHandler = (userId) => {
        setSelectedUserId(userId);
        setShowUserModal(true);
    }
    const drawRectObj = (obj) => {
        let strokeClr = obj.gender === "male" ? "lightblue" : "pink";
        let fillClr = "#DDD";
        return [
            <rect x={obj.x} y={obj.y} height={entityheight} width={entityWidth} stroke={strokeClr} style={{fill: fillClr}}></rect>,
            drawTextObj(obj)
        ]
    }
    const drawLineObj = (obj) => {
        return (
            <line x1={obj.x1} y1={obj.y1} x2={obj.x2} y2={obj.y2} stroke="#1f7770"></line>
        )
    }
    const renderRelationModal = () => {
        const hideModalHandler = () => {
            setSelectedUserId("");
            setShowRelationModal(false);
        }
        const onModalSave = () => {
            //reload tree
            setShowRelationModal(false);
            loadTree();
        }
        if(showRelationModal && selectedUserId) {
            return (
                <CreateRelationModal userId={selectedUserId} show={showRelationModal} onHide={hideModalHandler} onSave={onModalSave}/>            
            )
        }
    }

    const renderUserEditModal = () => {
        const hideModalHandler = () => {
            setSelectedUserId("");
            setShowUserModal(false);
        }
        const onModalSave = () => {
            //reload tree
            setShowUserModal(false);
            loadTree();
        }
        if(showUserModal && selectedUserId) {
            return (
                <EditUserModal userId={selectedUserId} show={showUserModal} onHide={hideModalHandler} onSave={onModalSave}/>            
            )
        }

    }
    const drawTextObj = (obj) => {
        return (
            <foreignObject x={obj.x} y={obj.y} height={entityheight} width={entityWidth}>
                <div className="node-info-wrapper" xmlns="http://www.w3.org/1999/xhtml">
                    <div className="node-info" xmlns="http://www.w3.org/1999/xhtml">
                        <div className="node-name" xmlns="http://www.w3.org/1999/xhtml">{obj.name}</div>
                    </div>
                    <div className="node-mouse-over" xmlns="http://www.w3.org/1999/xhtml">
                        <div className="node-mouse-over-elem" onClick={() => relationModalHandler(obj.id)}>
                            <i className="material-symbols-outlined text-primary">group</i>
                        </div>
                        <div className="node-mouse-over-elem">
                        <a href={`/family/tree/${obj.id}`}><i className="material-symbols-outlined text-primary">lightbulb</i></a>
                        </div>
                        <div className="node-mouse-over-elem" onClick={() => userModalHandler(obj.id)}>
                            <i className="material-symbols-outlined text-primary">edit</i>
                        </div>
                    </div>
                </div>
            </foreignObject>
        )
    }
    return (
        <div>
            <div>
                <button onClick={exportImage}>Export Tree</button>
            </div>
            <div className="svg-wrapper" id="familytree-wrapper">
                <svg id="familytree-svg" ref={svgRef} width={svgWidth} height={svgHeight}>
                {svgComponents.map(obj => {
                    if(obj.type === "rect") {
                        return drawRectObj(obj);
                    }
                    if(obj.type === "line") {
                        return drawLineObj(obj);
                    }
                })}
                </svg>
                {renderRelationModal()}
                {renderUserEditModal()}
            </div>
        </div>
    );
}

export default FamilyTree