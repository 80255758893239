import { configureStore } from '@reduxjs/toolkit';
import settingReducer from './setting/reducers';
import userReducer from './user/reducers';
import familyReducer from './family/reducers';
import stateReducer from './state/reducers';
export const store = configureStore({
  reducer: {
    setting: settingReducer,
    user:  userReducer,
    family: familyReducer,
    state: stateReducer
  }
});
