import React, { useState } from 'react';
import CreateFamily from './createFamily';
const { Modal } = require("react-bootstrap")

export const CreateFamilyModal = (props) => {
    return (
        <Modal size="lg" centered show={props.showAddFamily} onHide={props.handleFamilyClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Adding New Family
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CreateFamily onHide={props.handleFamilyClose} onSave={props.handleFamilySave}/>
            </Modal.Body>
        </Modal>
    )
}
