import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

export const Init = () => {
    const firebaseConfig = {
        apiKey: "AIzaSyAfNilAOdpGnifTtry8E1tOjmcOjIOXRrc",
        authDomain: "kulstry.firebaseapp.com",
        projectId: "kulstry",
        storageBucket: "kulstry.appspot.com",
        messagingSenderId: "212843553149",
        appId: "1:212843553149:web:dbc2f97fa56cc3c131ac14",
        measurementId: "G-L53R232CK5"
      };
      
      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
}
