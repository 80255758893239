import React, { useEffect, useState } from 'react';
import {Container, Row, Col, Card, Form, Button} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { Api } from '../../../api/api';
import userAction from '../../../store/user/actions';
import stateAction from '../../../store/state/actions';
import { Typeahead } from 'react-bootstrap-typeahead';
import { get_families } from '../../../store/family/selectors';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { CreateFamilyModal } from '../family/createFamilyModal';

const CreateUser = (props) => {
    const [showAddFamily, setShowAddFamily] = useState(false);
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);
    const[user, setUser] = useState({});
    const families = useSelector(get_families);
    const dispatch = useDispatch();
    let tryGap = 200;
    const handleFamilyAdd = () => {
        setShowAddFamily(true);
    }
    const handleFamilyClose = () => {
        setShowAddFamily(false);
    }
    const changeHandler = (evt) => {
      if(evt && evt.target.name) {
          let pname = evt.target.name;
          let pvalue = evt.target.value;
          setUser(c => {
            c[pname] = pvalue;
            return {...c};
          });
          if(props.changeHandler) {
            props.changeHandler(pname, pvalue);
          }
      }
    }
    const handleSave = () => {
      Api.addUser(user).then(resp => { 
        if(props.onSave) {
            props.onSave(resp);
        }
        setUser({});
        setSelected([]);
      }).catch(err => {  
      });
    }
    const handleFamilySave = () => {
        handleFamilyClose();
        dispatch(stateAction.setIsLoadFamilies(true));
    }
    useEffect(() => {
        let coptions = Object.values(families).map(fam => {
            return ({id: fam.id, label: fam.name});
        });
        setOptions(coptions);
    
    }, [families])
    useEffect(() => {
        if(props.user) {
            setUser({...props.user});
            if(props.user?.familyId) {
                setSelected([{id: props.user.familyId, label: families[props.user.familyId].name}])
            }
        }
    }, [props.user])
    return (
        <>
        <Container>
            <Row>
                <Col sm="12" style={{display: props.hideHeader ? "none" : ""}}>
                    <Card className="position-relative inner-page-bg bg-primary" style={{height: "150px"}}>
                        <div className="inner-page-title">
                            <h3 className="text-white">Few more steps</h3>
                            <p className="text-white">Add information about yourself</p>
                        </div>
                    </Card>
                </Col>
                <Col sm="12">
                    <Card>
                        <Card.Body>
                            <p style={{display: props.hideHeader ? "none" : ""}}>Fill all the details which you can</p>
                            <Form>
                                <Form.Group className="form-group">
                                    <Form.Label>Name:</Form.Label>
                                    <Form.Control type="text" id="name" name="displayName" value={user.displayName} onChange={changeHandler}/>
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label>Nick Name:</Form.Label>
                                    <Form.Control type="text" id="name" name="nickName" value={user.nickName} onChange={changeHandler}/>
                                </Form.Group>
                                <Form.Group className="form-group col-sm-6">
                                    <Form.Label className="form-label d-block">Gender:</Form.Label>
                                    <Form.Check className="form-check form-check-inline">
                                        <Form.Check.Input className="form-check-input" type="radio" name="gender" id="inlineRadio10" defaultValue="male" checked={user?.gender === "male"} onChange={changeHandler}/>
                                        <Form.Check.Label className="form-check-label" htmlFor="inlineRadio10"> Male</Form.Check.Label>
                                    </Form.Check>
                                    <Form.Check className="form-check form-check-inline">
                                        <Form.Check.Input className="form-check-input" type="radio" name="gender" id="inlineRadio11" defaultValue="female" checked={user?.gender === "female"} value={user.gender} onChange={changeHandler}/>
                                        <Form.Check.Label className="form-check-label" htmlFor="inlineRadio11">Female</Form.Check.Label>
                                    </Form.Check>
                                </Form.Group>
                                <div className="d-flex justify-content-end">
                                    <Button variant="primary" onClick={handleSave}>{props.saveText ? props.saveText : "Save/Next"}</Button>
                                    <Button variant="danger" style={{display: props.showCancel ? "" : "none"}}>Cancel</Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <CreateFamilyModal 
                handleFamilyClose={handleFamilyClose}
                showAddFamily={showAddFamily}
                handleFamilySave={handleFamilySave}
            />
        </Container>
        </>
    )
}

export default CreateUser;