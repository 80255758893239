import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './state.ts';
import _ from 'lodash'

export const familySlice = createSlice({
  name: 'family',
  initialState,
  reducers: {
    set: (state, action) => {
      if(action.payload && action.payload.familyId)  {
        state.families[action.payload.familyId] = {...action.payload.familyObj};
      }
    }
  },
});

export default familySlice.reducer;
