import React, { useEffect, useState } from 'react';
import {Container, Row, Col, Card, Form, Button, Modal} from 'react-bootstrap'
import CreateRelation from './createRelation';

const DrawFamily = (props) => {
    const [showAddRelation, setShowAddRelation] = useState(false);
    const [userInAction, setUserInAction] = useState({});
    const [root, setRoot] = useState({});
    const family = props.family;
    const userId = props.user.id;
    let fusers = (family && family.users) ? family.users : {};
    const getSpouse = (node) => {
        let spouse = [];
        if(node) {
            spouse = node.wife ? [...spouse, ...node.wife] : spouse;
            spouse = node.husband ? [...spouse, ...node.husband] : spouse;
        }
        return spouse;
    }
    const getRoot = (node, ix=0) =>{
        if(node.father && fusers[node.father]) {
            return getRoot(fusers[node.father]);
        } else if(node.husband && node.husband.length > 0 && node.husband[0] && fusers[node.husband[0]]) {
            return getRoot(fusers[node.husband[0]]);
        }
        return {id: node.id, name: node.displayName, childs: [], spouse: getSpouse(node)};
    }
    const handleAddRelations = (uid) => {
        if(uid && fusers[uid]) {
            setUserInAction(fusers[uid]);
            setShowAddRelation(true);
        }
    }
    const handleClose = () => {
        setShowAddRelation(false);
    }

    let pDict = {};
    const createTree = (node) =>{
        if(pDict[node.id]) {
            return;
        }
        if(!node.childs) {
            node.childs = [];
        }
        for(let key in fusers) {
            if(pDict[key]) {
                continue;
            }
            let fu = fusers[key];
            if(fu.father === node.id || fu.mother === node.id) {
                let spouse = node.wife ? [...node.wife] : [];
                spouse = node.husband ? [...spouse, ...node.husband] : spouse;
                node.childs.push({id: fu.id, name: fu.displayName, childs: [], spouse: getSpouse(fu)});
            }
        }
        pDict[node.id] = true;

        for(let ix in node.childs) {
            createTree(node.childs[ix]);
        }
    }
    useEffect(() => {
        if(fusers && Object.keys(fusers).length > 0) {
            let troot = {};
            troot =  getRoot(fusers[userId]);
            createTree(troot);
            setRoot(troot);
        }
    }, [fusers]);

    const renderNode = (node) => {
        if(!node || !node.id) {
            return ("");
        }
        return (
            <div key={`rnkey-${node.id}`} style={{position: "relative", marginTop: "20px"}} >
                <a href={`/user/${node.id}`}>
                <div>
                    <i className='fa fa-user-circle fa-4x'></i>
                </div>
                <div>{node.displayName}</div>
                </a>
                <div style={{position: "absolute",top: "0px",left: "50px",color: "blue", cursor: "pointer"}} onClick={() => handleAddRelations(node.id)}>
                    <i className='fa fa-plus fa-2x'></i>
                </div>
            </div>
        )

    }
    const drawTree = (sn, margin=0) => {
        if(!fusers[sn.id]) {
            return ("");
        }
        if(!sn.childs) {
            sn.childs = [];
        }
        if(!sn.spouse) {
            sn.spouse = [];
        }
        let node = fusers[sn.id]
        return (
            <div key={`dtkey-${node.id}`}>
                <div style={{marginLeft: `${margin}px`, width: "120px",marginTop: "5px",padding: "12px", borderRadius: "6px", border: "1px solid #abcdef"}}>
                    {renderNode(node)}
                    {sn.spouse.map(sid => {
                        return renderNode(fusers[sid])
                    })}
                </div>
                <div>
                    {sn.childs.map(ch => {
                        return drawTree(ch, margin+150)
                    })}
                </div>
            </div>
        )
    }
    if(Object.keys(root).length === 0) {
        <>
           <Container>
                <Row>
                    <Col sm="12">
                        No Family found
                    </Col>
                </Row>
            </Container>
        </>
    }

    return (
        <>
           <Container>
                <Row>
                    <Col sm="12">
                        {drawTree(root)}
                    </Col>
                </Row>
            </Container>
            <Modal size="lg" centered show={showAddRelation} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Adding relation for: {userInAction.displayName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateRelation baseUser={userInAction} exUserDict={fusers} familyId={props?.family?.id}/>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DrawFamily;