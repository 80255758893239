import {get, post, put} from './axiosWrapper';
import {Settings} from './settings';

export const Api = {
  inviteUser(obj) {
    let url = Settings.inviteUser();
    return post(url, {...obj}, true);
  },
  getUsers() {
    let url = Settings.getUsers();
    return get(url);
  },
  getSelf() {
    let url = Settings.getSelf();
    return get(url);
  },
  getUser(userId) {
    let url = Settings.getUser(userId);
    return get(url);
  },
  getUserByUid(uid) {
    let url = Settings.getUserByUid(uid);
    return get(url);
  },
  addUser(userObj) {
    let url = Settings.addUser();
    return post(url, {...userObj}, true);
  },
  updateUser(userId, userObj) {
    let url = Settings.updateUser(userId);
    return put(url, {...userObj}, true);
  },
  getRelations(userId) {
    let url = Settings.getRelations(userId);
    return get(url);
  },
  addRelation(relation) {
    let url = Settings.addRelation();
    return post(url, {relation: {...relation}}, true);
  },
  getFamilies() {
    let url = Settings.getFamilies();
    return get(url);
  },
  getFamily(familyId) {
    let url = Settings.getFamily(familyId);
    return get(url);
  },
  getFamilyDetails(familyId) {
    let url = Settings.getFamilyDetails(familyId);
    return get(url);
  },
  createFamily(familyObj) {
    let url = Settings.createFamily();
    return post(url, familyObj, true);
  },
  getFamilyTreeForUser(userId) {
    let url = Settings.getFamilyTreeForUser(userId);
    return get(url);
  },
  convertToPng(blob) {
    let url = Settings.getFamilyTreeConvert();
    return post(url, blob, false);
  }
}
