let apibase = "https://api.kulstry.com";
//apibase = "http://localhost:5000"

export const Settings = {
	inviteUser() {
		return `${apibase}/invite`;
	},
	addUser()  {
		return `${apibase}/user`;
	},
	getSelf() {
		return `${apibase}/user/me`;
	},
	getUsers()  {
		return `${apibase}/user`;
	},
	getUser(userId)  {
		return `${apibase}/user/${userId}`;
	},
	getUserByUid(uid)  {
		return `${apibase}/user/uid/${uid}`;
	},
	updateUser(userId) {
		return `${apibase}/user/${userId}`;
	},
	getRelations(userId) {
		return `${apibase}/relation?userId=${userId}`;
	},
	addRelation() {
		return `${apibase}/relation/user`;
	},
	getFamilies() {
		return `${apibase}/family`;
	},
	getFamily(familyId) {
		return `${apibase}/family/${familyId}`;
	},
	getFamilyDetails(familyId) {
		return `${apibase}/family/${familyId}/details`;
	},
	createFamily() {
		return `${apibase}/family`;
	},
	getFamilyTreeForUser(userId) {
		return `${apibase}/familytree/user/${userId}`;
	},
	getFamilyTreeConvert(blob) {
		return `${apibase}/familytree/convert`;
	},
}
