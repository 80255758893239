import React from 'react'

import Header from '../components/partials/dashboard/HeaderStyle/header'
import RightSidebar from '../components/partials/dashboard/SidebarStyle/rightsidebar'
import Sidebar from '../components/partials/dashboard/SidebarStyle/sidebar'
import Footer from '../components/partials/dashboard/FooterStyle/footer'
import ShareOffcanvas from '../components/share-offcanvas'
import SettingOffCanvas from '../components/setting/SettingOffCanvas'
import UserRouters from '../router/user-router'

const Dashboard = () => {
    return (
        <>
            <div className="wrapper">
                <Header />
                <div className="main-content">
                    <div id="content-page" className="content-page">
                        <UserRouters />
                    </div>
                </div>
            </div>
            <Footer />
            <ShareOffcanvas />
        </>
    )
}

export default Dashboard
