import loader from '../../../assets/images/page-img/page-load-loader.gif'
const { useSelector } = require("react-redux")
const { is_loading } = require("../../../store/state/selectors")

const UserLoading = () => {
    const isLoading = useSelector(is_loading);
    if(isLoading) {
        return (
            <div className="col-sm-12 text-center" id="loading">
                <img src={loader} alt="loader" style={{height: "100px"}}/>
            </div>
        )
    } else {
        return ("");
    }
}

export default UserLoading;