import React from 'react'
import {Switch,Route} from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import SearchFamily from '../views/dashboard/family/searchFamily'
import CreateFamily from '../views/dashboard/family/createFamily'
import Family from '../views/dashboard/family/family'
import UiModals from '../views/dashboard/ui-kit/ui-modal'
import FormWizard from '../views/dashboard/from/form-wizard'
import Index from '../views/dashboard'
import FamilyMembers from '../views/dashboard/family/familyMembers'
import CreateMyRelation from '../views/dashboard/family/createMyRelation'
import CreateUserRelation from '../views/dashboard/family/createUserRelation'
import FamilyTree from '../views/dashboard/family/familyTree'


const UserRouters = () => {
    return (
        <TransitionGroup>
            <CSSTransition classNames="fadeUp" timeout={300}>
                <Switch>
                    <Route path="/"  exact component={Index} />
                    <Route path="/family/relation/:userId" exact component={CreateUserRelation} />
                    <Route path="/family/relation" exact component={CreateMyRelation} />
                    <Route path="/family/members"  exact component={FamilyMembers} />
                    <Route path="/family/tree"  exact component={FamilyTree} />
                    <Route path="/family/tree/:userId"  exact component={FamilyTree} />
                    <Route path="/user/:userId"  exact component={Family} />
                    <Route path="/popover"  exact component={UiModals} />                    
                    <Route path="/family"  exact component={SearchFamily} />
                    <Route path="/family/create"  exact component={CreateFamily} />
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default UserRouters
