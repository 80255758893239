//router
import IndexRouters from "./router/index"
import UserLoading from "./views/dashboard/auth/user-loading";
//scss
import "./assets/scss/socialv.scss"
import "./assets/scss/customizer.scss"

function App() {
  return (
    <div className="App">
      <UserLoading />
      <IndexRouters />
    </div>
  );
}

export default App;
