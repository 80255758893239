import React from 'react';
import {Container, Row, Col, Card, Tab, Form, Button, Nav} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import userAction from '../../../store/user/actions';

const SearchFamily = () => {
    const handleSave = () => {

    }
    return(
        <>
          <Container>
              <Tab.Container defaultActiveKey="first">
            <Row>
                <Col lg="12">
                    <Card>
                        <Card.Body className="p-0">
                            <div>
                                <Nav as="ul" variant="pills" className="iq-edit-profile row">
                                    <Nav.Item as="li" className="col-md-3 p-0">
                                        <Nav.Link  eventKey="first" role="button">
                                            Search if you family exist
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={12}>
                    {/* <div className="iq-edit-list-data"> */}
                        <Tab.Content>
                            <Tab.Pane eventKey="first" className="fade show">
                                <Card>
                                    <Card.Header className="d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">Personal Information</h4>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form>
                                            <Form.Group className="form-group align-items-center">
                                                <Col md="12">
                                                    <div className="profile-img-edit">
                                                    </div>
                                                </Col>
                                            </Form.Group>
                                            <Row className="align-items-center">
                                                <Link to="/family/create" className="float-end">Create Family</Link>                                                
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>
                        </Tab.Content>
                    {/* </div> */}
                </Col>
            </Row>
            </Tab.Container>
          </Container>        
        </>
    )
}

export default SearchFamily;