import React, { useEffect, useState } from 'react';
import {Container, Row, Col, Card, Form, Button} from 'react-bootstrap'

const NoAccess = (props) => {
    return (
        <>
        <Container>
            <Row>
                <Col sm="12" style={{display: props.hideHeader ? "none" : ""}}>
                    <Card className="position-relative inner-page-bg bg-primary" style={{height: "150px"}}>
                        <Card.Body>
                            <div className="inner-page-title">
                                <h3 className="text-white">Thank you for showing interest</h3>
                                <p className="text-white">Kulstry is invite only for now, please request admin for access (admin@kulstry.com)</p>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default NoAccess;