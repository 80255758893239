import React, { useState } from 'react';
import {Container, Row, Col, Card, Form, Button, Modal} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { Api } from '../../../api/api';
import { get_families } from '../../../store/family/selectors';
import userAction from '../../../store/user/actions';
import { get_user } from '../../../store/user/selectors';
import CreateFamily from './createFamily';
import CreateRelation from './createRelation';

const CreateMyRelation = (props) => {
    const user = useSelector(get_user);
    const families = useSelector(get_families);
    return (
        <CreateRelation user={{...user}} families={{...families}} />
    )
}

export default CreateMyRelation;